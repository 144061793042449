import { API_URL, deleteRequest, getRequest, postRequest, putRequest } from '../helpers/api';
const getTimer = () => getRequest(`${API_URL}/lottery/timer`);
const getLastAward = () => getRequest(`${API_URL}/lottery/result`);
const getHistoryAward = (id) => getRequest(`${API_URL}/lottery/history/${id}`);
const getAllAwards = () => {
    return getRequest(`${API_URL}/admin/lottery/awards`, true);
};
const addAwards = (name, ticket_count) => postRequest(`${API_URL}/admin/lottery/awards`, {
    name,
    ticket_count
}, true);
const deleteAward = (lotteryAwardId) => deleteRequest(`${API_URL}/admin/lottery/awards/${lotteryAwardId}`, true);
const editAward = (lotteryAwardId, name, ticket_count) => {
    return putRequest(`${API_URL}/admin/lottery/awards/${lotteryAwardId}`, { name, ticket_count, }, true);
};
export { getLastAward, getHistoryAward, getTimer, getAllAwards, addAwards, deleteAward, editAward, };
