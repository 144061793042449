import { LotteryAwardKeyEnum, LotteryAwardLabelEnum } from '@/assets/ts/enums/lottery-award.enum';
export const getAwardLabel = (key) => {
    if (key === LotteryAwardKeyEnum.Shard) {
        return LotteryAwardLabelEnum.Shard;
    }
    else if (key === LotteryAwardKeyEnum.Ticket || key === LotteryAwardKeyEnum.TicketX2) {
        return key === LotteryAwardKeyEnum.Ticket ? LotteryAwardLabelEnum.Ticket : LotteryAwardLabelEnum.TicketX2;
    }
    else if (key.indexOf('kw.') > -1 || key.indexOf('m') > -1) {
        return key;
    }
    return '';
};
