export var LotteryAwardKeyEnum;
(function (LotteryAwardKeyEnum) {
    LotteryAwardKeyEnum["Shard"] = "odlamek postaci";
    LotteryAwardKeyEnum["Ticket"] = "strzal";
    LotteryAwardKeyEnum["TicketX2"] = "strzal x2";
})(LotteryAwardKeyEnum || (LotteryAwardKeyEnum = {}));
export var LotteryAwardLabelEnum;
(function (LotteryAwardLabelEnum) {
    LotteryAwardLabelEnum["Shard"] = "od\u0142amek postaci";
    LotteryAwardLabelEnum["Ticket"] = "dodatkowy strza\u0142";
    LotteryAwardLabelEnum["TicketX2"] = "dodatkowy strza\u0142 x2";
})(LotteryAwardLabelEnum || (LotteryAwardLabelEnum = {}));
