import { editAward } from '@/assets/ts/api/lottery';
import { defineComponent, ref } from 'vue';
import Modal from '@/components/layout/modal/Modal.vue';
import { getAwardLabel } from '@/assets/ts/models/lottery/award';
export default defineComponent({
    components: {
        Modal
    },
    props: {
        lotteryAwardId: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        ticketCount: {
            type: Number,
            required: true,
        },
        updatedAt: {
            type: Date,
            required: false,
            default: null,
        },
    },
    setup(props) {
        const updateAwardModalRef = ref();
        const awardNameRef = ref(props.name);
        const newAwardNameRef = ref('');
        const ticketCountRef = ref(props.ticketCount);
        const newTicketCountRef = ref(props.ticketCount);
        const requestError = ref(false);
        function updateAward() {
            editAward(props.lotteryAwardId, newAwardNameRef.value, newTicketCountRef.value).then((data) => {
                awardNameRef.value = data.award.name;
                ticketCountRef.value = data.award.ticket_count;
            }).catch((error) => {
                console.error(error);
                requestError.value = true;
            }).finally(() => toggleModal());
        }
        function cancel() {
            awardNameRef.value = props.name;
            ticketCountRef.value = props.ticketCount;
            toggleModal();
        }
        function toggleModal() {
            updateAwardModalRef.value.toggleModal();
        }
        return {
            updateAward,
            cancel,
            getAwardLabelText: getAwardLabel,
            toggleModal,
            updateAwardModalRef,
            newAwardNameRef,
            requestError,
            awardNameRef,
            ticketCountRef,
            newTicketCountRef,
        };
    },
    methods: { getAwardLabel },
});
