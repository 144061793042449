import { getAllAwards } from '@/assets/ts/api/lottery';
import { defineComponent, onMounted, ref } from 'vue';
import AdminPanelLoader from '@/components/panel/panel-components/AdminPanelLoader.vue';
import AdminPanelAwardRecord from './award-record/AdminPanelAwardRecord.vue';
export default defineComponent({
    components: {
        AdminPanelLoader,
        AdminPanelAwardRecord
    },
    setup() {
        const isLoading = ref(false);
        const awards = ref([]);
        const requestError = ref(false);
        onMounted(() => getAwards());
        const getAwards = () => {
            isLoading.value = true;
            getAllAwards().then((awardsIncome) => (awards.value = awardsIncome.awards)).finally(() => (isLoading.value = false));
        };
        return {
            awards,
            isLoading,
            requestError,
        };
    },
});
